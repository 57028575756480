<template>
  <div id="sideBar">
    <Dialog ref="dialog" :config="config" />
    <div ref="chitchat" class="chitchat" id="dragbtn" @click.stop=" hide = !hide">
      <!-- <img src="../../assets/hometop/message.svg" alt="" v-if="hide == false" />
      <i v-else-if="hide == true" class="el-icon-close"></i> -->

      <div class="lainxi" :class="{ modle: hide == 'true' }">
        <img src="../../assets/hometop/whatAPP1.svg" alt="" @click="lianxi
        " />

      </div>

      <!-- <div @click="skype" class="skype">
        <img src="../../assets/hometop/skyep.png" alt="">
      </div> -->
      <div class="rk" :class="{ raku: hide == true }">
        <img @click.stop="rkchat" src="../../assets/hometop/raku1.svg" alt="" />

      </div>
    </div>

    <div id="topBtn" class="sideBarGgroup">
      <div :class="isHover == 7 ? 'sideBarGgroupOpt top' : 'sideBarGgroupOpt'" @mouseenter.stop="isHover = 7"
        @mouseleave.stop="isHover = -1" @click="toTop" style="cursor: pointer">
        <i class="el-icon-arrow-up"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Dialog from '../../components/public/Dialog'

export default {
  data() {
    return {
      isHover: -1,
      config: {
        top: '20vh',
        width: '500px',
        title: this.$fanyi('是否登录'),
        btnTxt: [this.$fanyi('前往登录'), this.$fanyi('关闭')],
      },
      userDataInfo: {},
      hide: false,
    }
  },
  components: { Dialog },
  computed: {},
  created() {
    this.$api.EuropegetUserInfo().then((res) => {
      this.userDataInfo = res.data
    })
  },

  mounted() {








    // // 控制聊天按钮可以自由移动
    // var dv = this.$refs.chitchat
    // var x = 0
    // var y = 0
    // var l = 0
    // var t = 0
    // let firstTime = null
    // let lastTime = null
    // var isDown = false
    // //鼠标按下事件
    // dv.onmousedown = function (e) {
    //   document.getElementById('dragbtn').setAttribute('drag-flag', false)

    //   firstTime = new Date().getTime()
    //   //获取x坐标和y坐标
    //   x = e.clientX
    //   y = e.clientY
    //   //获取左部和顶部的偏移量
    //   l = dv.offsetLeft
    //   t = dv.offsetTop
    //   //开关打开
    //   isDown = true
    //   //设置样式
    //   dv.style.cursor = 'move'
    //   document.body.style.userSelect = 'none'
    // }
    // // 鼠标移动
    // window.onmousemove = function (e) {
    //   // e.preventDefault()
    //   if (isDown == false) {
    //     return
    //   }

    //   //获取x和y
    //   var nx = e.clientX
    //   var ny = e.clientY
    //   //计算移动后的左偏移量和顶部的偏移量
    //   var nl = nx - (x - l)
    //   var nt = ny - (y - t)
    //   dv.style.left = nl + 'px'
    //   dv.style.top = nt + 'px'
    //   document.body.style.userSelect = 'auto'
    // }
    // //鼠标抬起事件
    // let that = this
    // dv.onmouseup = function (e) {
    //   isDown = false
    //   lastTime = new Date().getTime()
    //   //开关关闭
    //   // document.onmousemove = document.onmouseup = null
    //   // ?用时间差判断是拖拽还是点击
    //   console.log(lastTime, firstTime)
    //   if (lastTime - firstTime > 150) {
    //     document.getElementById('dragbtn').setAttribute('data-flag', true)
    //   } else {
    //     document.getElementById('dragbtn').setAttribute('data-flag', false)
    //   }
    //   const isClick = document
    //     .getElementById('dragbtn')
    //     .getAttribute('data-flag')
    //   console.log(isClick)
    //   if (isClick !== 'true') {
    //     // if (that.$store.state.userInfo) {
    //     // let iTop = (window.screen.availHeight - 30 - 700) / 2
    //     // //获得窗口的水平位置
    //     // let iLeft = (window.screen.availWidth - 10 - 1260) / 2
    //     // window.open(
    //     //   'https://rakuchatus.rakumart.cn/#/?id=' +
    //     //     that.$store.state.userInfo.user_id,
    //     //   that.$store.state.userInfo,
    //     //   'height=700, width=1260, left=' +
    //     //     iLeft +
    //     //     ', top=' +
    //     //     iTop +
    //     //     ', toolbar=no, menubar=no, scrollbars=yes, resizable=no, location=no, status=no'
    //     // )
    //     // } else {
    //     //   that.$refs.dialog.open(
    //     //     that.$fanyi('是否前往登录?'),
    //     //     () => {
    //     //       that.$store.commit('getactivePage', that.$route.fullPath)
    //     //       that.$fun.toPage('/login')

    //     //     },
    //     //     () => { }
    //     //   )
    //     // }
    //   }
    //   document.onmousemove = null
    //   // document.onmouseup = null
    // }
    // // 控制top按钮在一定高度才显示
    // window.onscroll = function () {
    //   let scrollTop =
    //     document.documentElement.scrollTop || document.body.scrollTop
    //   if (scrollTop >= 500) {
    //     document.getElementById('topBtn').style.display = 'block'
    //   } else if (scrollTop <= 530) {
    //     document.getElementById('topBtn').style.display = 'none'
    //   }
    // }
  },
  methods: {

    rkchat() {
      if (this.$store.state.userInfo) {
        let iTop = (window.screen.availHeight - 30 - 700) / 2
        //获得窗口的水平位置
        let iLeft = (window.screen.availWidth - 10 - 1260) / 2
        window.open(
          'https://rakuchatus.rakumart.cn#/?id=' +
          this.$store.state.userInfo.user_id,
          this.$store.state.userInfo,
          'height=700, width=1260, left=' +
          iLeft +
          ', top=' +
          iTop +
          ', toolbar=no, menubar=no, scrollbars=yes, resizable=no, location=no, status=no'
        )

      } else {
        this.$refs.dialog.open(
          this.$fanyi('是否前往登录?'),
          () => {
            this.$store.commit('getactivePage', this.$route.fullPath)
            this.$fun.toPage('/login')

          },
          () => { }
        )
      }
    },
    lianxi() {
      // ?id=' +
      //   this.$store.state.userInfo.user_id,
      //   this.$store.state.userInfo
      let iTop = (window.screen.availHeight - 30 - 700) / 2
      //获得窗口的水平位置 +86 150 2439 0114
      let iLeft = (window.screen.availWidth - 10 - 1260) / 2
      window.open(
        'https://wa.me/+8615957125759',
        'height=700, width=1260, left=' +
        iLeft +
        ', top=' +
        iTop +
        ', toolbar=no, menubar=no, scrollbars=yes, resizable=no, location=no, status=no'
      )
      // if (this.$store.state.userInfo) {

      // } else {
      //   this.$refs.dialog.open(
      //     this.$fanyi('是否前往登录?'),
      //     () => {
      //       this.$store.commit('getactivePage', this.$route.fullPath)
      //       this.$fun.toPage('/login')

      //     },
      //     () => { }
      //   )
      // }
    },
    skype() {
      window.open('https://join.skype.com/invite/pQ1tOpS0Qm88')
    },
    chat(e) {
      let element = document.getElementById("zsiq_float");
      // 创建事件

      var event = document.createEvent("MouseEvents");
      // 定义事件 参数： type, bubbles, cancelable
      event.initEvent("click", true, true);


      // 触发对象可以是任何元素或其他事件目标
      element.dispatchEvent(event);


    },
    toTop() {
      let timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 60)
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed
        this.isTop = true
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 2)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../css/mixin';

#sideBar {
  // option选项的大小
  $sideBarOptSize: 50px;

  // width: $sideBarOptSize;
  position: fixed;
  right: 10px;


  bottom: 120px;
  z-index: 10;

  .sideBarGgroup {
    display: none;
    // 侧边栏的圆角角度
    position: absolute;

    margin-bottom: 50px;
    left: 0px;
    top: -168px;

    .sideBarGgroupOpt {
      font-weight: bold;



      &.top {
        color: $homePageSubjectColor;
      }

      .el-icon-arrow-up {
        font-size: 30px;
        font-weight: bold;
        // margin-bottom: 5px;

        &:hover {
          color: $homePageSubjectColor;
        }
      }

      border-radius: 50%;
      background-color: white;

      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      $sideBarIconSize: 50px;
      box-shadow: 0px 9px 8px 0px rgba(41, 41, 41, 0.11);

      img {
        width: $sideBarIconSize;
        height: $sideBarIconSize;
      }
    }
  }
}

.chitchat {
  position: relative;
  bottom: -68px;
  transform: translate(0, -50%);
  // right: -25px;
  background: #ffffff;

  border-radius: 3px;
  width: 60px;
  z-index: 0;
  height: 60px;
  // background: #ff730b !important;

  cursor: pointer;

  // .xiaoxi {

  //   min-width: 16px;
  //   height: 16px;
  //   left: 25px;
  //   top: 0px;
  //   position: absolute;
  //   font-size: 12px;
  //   font-family: Roboto-Regular, Roboto;
  //   font-weight: 400;
  //   color: #FFFFFF;
  //   line-height: 16px;
  //   text-align: center;
  //   background: #000000;
  //   border-radius: 10px;
  //   padding: 0 5px;
  // }

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 14px 20px;

  .rk {

    opacity: 1;
    top: -68px !important;
    position: absolute !important;

    // transform: translate(0, -50%);
    right: 0px;
    background: #ffffff;

    width: 58px;
    height: 58px;
    border-radius: 50px;
    background: #0070be;


    display: flex;
    align-items: center;
    justify-content: center;

    // .rkxiaoxi {
    //   min-width: 16px;
    //   height: 16px;
    //   left: 25px;
    //   top: 0px;
    //   position: absolute;
    //   font-size: 12px;
    //   font-family: Roboto-Regular, Roboto;
    //   font-weight: 400;
    //   color: #FFFFFF;
    //   line-height: 16px;
    //   text-align: center;
    //   background: #FF730B;
    //   border-radius: 10px;
    //   padding: 0 5px;
    // }

    // transition: all 0.8s;
    img {
      width: 58px;
      height: 58px;


    }
  }

  .skype {
    opacity: 1;
    top: -135px;
    position: absolute;
    transition: top 0.8s;
    // transform: translate(0, -50%);
    right: 0px;


    width: 58px;
    height: 58px;
    border-radius: 50px;
    background: #00AFEC;


    display: flex;
    align-items: center;
    justify-content: center;

    // .rkxiaoxi {
    //   min-width: 16px;
    //   height: 16px;
    //   left: 25px;
    //   top: 0px;
    //   position: absolute;
    //   font-size: 12px;
    //   font-family: Roboto-Regular, Roboto;
    //   font-weight: 400;
    //   color: #FFFFFF;
    //   line-height: 16px;
    //   text-align: center;
    //   background: #FF730B;
    //   border-radius: 10px;
    //   padding: 0 5px;
    // }

    // transition: all 0.8s;
    img {
      width: 58px;
      height: 58px;


    }
  }

  .lainxi {
    // bottom: 0;
    top: 0;
    border-radius: 50%;
    position: absolute;
    z-index: 9999;
    opacity: 1;

    // transform: translate(0, -50%);
    right: 0px;

    display: none;
    width: 60px;
    height: 60px;
    background: linear-gradient(360deg, #20b038 0%, #60d66a 100%);
    box-shadow: 0px 6px 10px 0px rgba(41, 41, 41, 0.06);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    // transition: top 0.8s linear;

    // .langxiaoxi {

    //   min-width: 16px;
    //   height: 16px;
    //   left: 25px;
    //   top: 0px;
    //   position: absolute;
    //   font-size: 12px;
    //   font-family: Roboto-Regular, Roboto;
    //   font-weight: 400;
    //   color: #FFFFFF;
    //   line-height: 16px;
    //   text-align: center;
    //   background: #FF730B;
    //   border-radius: 10px;
    //   padding: 0 5px;
    // }

    img {
      width: 58px;
      height: 58px;

      // box-shadow: 0px 6px 10px 0px rgba(41, 41, 41, 0.06);
    }
  }

  .el-icon-close {
    color: #fff;
    font-size: 30px;
    // transform: rotate(360deg);
    // transition: all 0.8s;
  }

  .modle {
    top: -60px;
    // display: block;
    opacity: 1;

    transition: all 0.8s;

    img {
      width: 48px;
      height: 48px;

      // box-shadow: 0px 6px 10px 0px rgba(41, 41, 41, 0.06);
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  .raku {
    top: -120px;
    // display: block;
    opacity: 1;

    transition: all 0.8s;

    &:hover {
      transform: scale(1.1);
    }

    img {
      width: 48px;
      height: 48px;


    }
  }

  img {
    width: 28px;
    height: 28px;

    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.8s;
    transform: rotate(360deg);
  }

  span {
    font-size: 14px;
    font-weight: 400;
    color: #404040;
  }
}

/deep/ .el-dialog__body {
  padding: 25px 60px 30px 30px !important;

  span {
    font-size: 16px !important;
  }
}
</style>
